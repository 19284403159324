.app-container { 
  background-image: url('/src/Image/result.jpg');
  background-size: cover; 
  background-position: center; 
  background-attachment: fixed; 
  overflow: hidden;
}

@keyframes sleigh {
    0% {
      transform: translateX(100vw) translateY(0);
    }
    25% {
      transform: translateX(75vw) translateY(-10px);
    }
    50% {
      transform: translateX(50vw) translateY(10px);
    }
    75% {
      transform: translateX(25vw) translateY(-10px);
    }
    100% {
      transform: translateX(-550px) translateY(0);
    }
  }
  
  .animate-sleigh {
    animation: sleigh 6s ease-in-out forwards;
    position: absolute;
  }
  