/* App.css */

/* Centrage de la Card sur la page */
.app-container-draw {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('/src/Image/home.jpg');
    background-size: cover; 
    background-position: center; 
    background-attachment: fixed; 
    overflow: hidden;
  }
  
  /* Personnalisation de la Card */
  .custom-card {
    width: 500px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Icône de Noël pour ajouter une touche festive */
  .gift-icon {
    color: #D9B70D; /* Or pour l'esprit de Noël */
    /* margin-bottom: 20px; */
  }
  
  /* Bouton personnalisé */
  .p-button {
    background-color: #BF8D30; /* Bronzes doré pour l'effet Noël */
    border-color: #BF8D30;
    color: #fff;
    
  }
  
  .p-button:hover {
    background-color: #D9B70D;
    border-color: #D9B70D;
  }
  
  /* Rendre le texte de la Card plus large et lisible */
  .card-content {
    padding: 20px;
  }

  @keyframes shake {
    0% { transform: translate(0, 0) rotate(0deg); }
    10% { transform: translate(-5px, -2px) rotate(-5deg); }
    20% { transform: translate(5px, 2px) rotate(5deg); }
    30% { transform: translate(-5px, 2px) rotate(-3deg); }
    40% { transform: translate(5px, -2px) rotate(3deg); }
    50% { transform: translate(0, 0) rotate(0deg); }
    60% { transform: translate(-4px, -1px) rotate(-4deg); }
    70% { transform: translate(4px, 1px) rotate(4deg); }
    80% { transform: translate(-4px, 1px) rotate(-2deg); }
    90% { transform: translate(4px, -1px) rotate(2deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
  }
  